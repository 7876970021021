
window.$ = window.jQuery = require('jquery'); // not sure if you need this at all
$ = jQuery;
import { Tooltip, Toast, Popover } from 'bootstrap';
import { defineJQueryPlugin, execute, findShadowRoot, getElement, getUID, isRTL, noop } from 'bootstrap/js/src/util/index.js'

import EventHandler from 'bootstrap/js/src/dom/event-handler.js'
/*!
/**
 * Constants
 */
  var ClassName = {
    FADE: 'fade',
    SHOW: 'show'
  };
  var Event = {
    HIDE: "hide" + EVENT_KEY,
    HIDDEN: "hidden" + EVENT_KEY,
    SHOW: "show" + EVENT_KEY,
    SHOWN: "shown" + EVENT_KEY,
    INSERTED: "inserted" + EVENT_KEY,
    CLICK: "click" + EVENT_KEY,
    FOCUSIN: "focusin" + EVENT_KEY,
    FOCUSOUT: "focusout" + EVENT_KEY,
    MOUSEENTER: "mouseenter" + EVENT_KEY,
    MOUSELEAVE: "mouseleave" + EVENT_KEY,
    CONFIRMED: "confirmed" + EVENT_KEY,
    CANCELED: "canceled" + EVENT_KEY,
    KEYUP: "keyup" + EVENT_KEY
  };
  var Keymap = {
    13: 'Enter',
    27: 'Escape',
    39: 'ArrowRight',
    40: 'ArrowDown'
  };
var Selector = {
    TITLE: '.popover-header',
    CONTENT: '.confirmation-content',
    BUTTONS: '.confirmation-buttons .btn-group'
  };
var NAME = 'confirmation';
var VERSION = '4.2.1';
var DATA_KEY = "bs." + NAME;
var EVENT_KEY = "." + DATA_KEY;
var JQUERY_NO_CONFLICT = jQuery.fn[NAME];
var BTN_CLASS_BASE = 'h-100 d-flex align-items-center confirmation-btn';
var BTN_CLASS_DEFAULT = 'btn btn-sm';

//const SELECTOR_TITLE = '.popover-header'
//const SELECTOR_CONTENT = '.popover-body'
//this_attributes manque . (je pense) !!!
const Default = {
  ...Popover.Default, 
  _attributes: {},
  _selector: null,
    confirmationEvent: undefined,
    copyAttributes: 'href target',
  content: '',    
  trigger: 'click',
    placement: 'top',
  title:"Etês-vous sûr?",
  offset: [0, 8],
  placement: 'top',
      // @formatter:off
  template: "\n<div class=\"popover confirmation-window\">\n  <div class=\"arrow\"></div>\n  <h3 class=\"popover-header\"></h3>\n  <div class=\"popover-body\">\n    <p class=\"confirmation-content\"></p>\n    <div class=\"confirmation-buttons text-center\">\n      <div class=\"btn-group\"></div>\n    </div>\n  </div>\n</div>", // @formatter:on
  trigger: 'click',
  
  onConfirm: jQuery.noop,
  onCancel: jQuery.noop,
    popout: true,
    singleton: false,
  btnOkClass: BTN_CLASS_DEFAULT + " btn-primary",
    btnOkLabel: 'Oui',
    btnOkIconClass: '',
    btnOkIconContent: '',
    btnCancelClass: BTN_CLASS_DEFAULT + " btn-secondary",
    btnCancelLabel: 'Non',
    btnCancelIconClass: '',
    btnCancelIconContent: '',
    buttons: [],
}

const DefaultType = {
  ...Popover.DefaultType,
  title: '(string|element|function)',
  content: '(null|string|element|function)',    
    copyAttributes: '(string|array)',
  onConfirm: 'function',
  onCancel: 'function',  
  _selector: '(null|string|boolean)',
  
    popout: 'boolean',
    singleton: 'boolean',
    btnOkClass: 'string',
    btnOkLabel: 'string',
    btnOkIconClass: 'string',
    btnOkIconContent: 'string',
    btnCancelClass: 'string',
    btnCancelLabel: 'string',
    btnCancelIconClass: 'string',
    btnCancelIconContent: 'string',
    buttons: 'array',
}

/**
 * Class definition
 */

class Confirmation extends Tooltip {
  // Getters
  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get NAME() {
    return NAME
  }
    uid;
    constructor(element,config){
        super(element,config);
        //COMMECA CA NE FONCTIONNE PAS
        var titleAttr = this._element.getAttribute('data-bs-original-title');
        if(titleAttr != undefined && titleAttr != ""){
            this._config.title = titleAttr;
        }
        
     //   this._element.setAttribute("data-bs-original-title", this._getTitle()/*this._config.title*/);
        this._config.html = true;
      //  this._config.content = "<a class='btn btn-info'>ok</a><a class='btn btn-danger'>non</a>";
//        this._element.setAttribute("data-bs-content", this._config.content);
        this.initSelector(config);
        
        if (this._config.confirmationEvent === undefined) {
            this._config.confirmationEvent = this._config.trigger;
        }

        if (!this._config.selector) {
            this._copyAttributes();
        }
        
        this.initButtons();
        this._setConfirmationListeners();
        
        
    }
    
   
    initSelector(config){
        var _this = this;
    
        if (config.selector) {
          console.log("config.selector is define : " + config.selector);
        // container of buttons
        config.selector = config.rootSelector + " " + config.selector;
        _this._config._selector = config.selector;
          console.log("define new _selector : " + _this._config._selector);
      } else if (config._selector) {
        // children of container
        _this._config._selector = config._selector;
        _this._config._isDelegate = true;
          console.log("config._selector is defined : " + _this._config._selector);
      } else {
        // standalone
        _this._config._selector = config.rootSelector;
          console.log("it s root selector : " + _this._config._selector);
      }
    }
  // Overrides
  _isWithContent() {
        return this._getTitle() || this._getContent()
  }

  // Private
  _getContentForTemplate() {
    return {
      [Selector.TITLE]:  this._getTitle(),
      [Selector.CONTENT]: this._getContent(),
      //[Selector.BUTTONS]: this.getButtonsView(),
    }
  }

   _setConfirmationListeners() {
      var self = this;
      if (!this._config.selector) 
      {
        jQuery(this._element).on(this._config.trigger, function (e, ack) {
          if (!ack) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
          }
        }); // manage singleton

        jQuery(this._element).on(Event.SHOWN, function () {
          if (self._config.singleton) {
            // close all other popover already initialized
            jQuery(self._config._selector).not(jQuery(this)).filter(function () {
              return jQuery(this).data(DATA_KEY) !== undefined;
            }).confirmation('hide');
          }
        });
      }
      else {
      // cancel original event
      $(this.element).on(this._config.trigger, this._config.selector, (e, ack) => {
        if (!ack) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        }
      });
    }
      
      if (!this._config._isDelegate) {
        // manage popout
        this.eventBody = false;
        this.uid = this._element.id || Confirmation.getUID(NAME + "_group");
        jQuery(this._element).on(Event.SHOWN, function () {
          if (self._config.popout && !self.eventBody) {
            self.eventBody = $('body').on(Event.CLICK + "." + self.uid, function (e) {
              if (jQuery(self._config._selector).is(e.target) || jQuery(self._config._selector).has(e.target).length > 0) {
                    return;
              } // close all popover already initialized


              $(self._config._selector).filter(function () {
                return $(this).data(DATA_KEY) !== undefined;
              }).confirmation('hide');
              $('body').off(Event.CLICK + "." + self.uid);
              self.eventBody = false;
            });
          }
        });
      }
      
   
    }
    _setStandardButtons($tip) {
      var buttons = [{
        "class": this._config.btnOkClass,
        label: this._config.btnOkLabel,
        iconClass: this._config.btnOkIconClass,
        iconContent: this._config.btnOkIconContent,
        attr: this._config._attributes
      }, {
        "class": this._config.btnCancelClass,
        label: this._config.btnCancelLabel,
        iconClass: this._config.btnCancelIconClass,
        iconContent: this._config.btnCancelIconContent,
        cancel: true
      }];
      this._config.buttons = buttons;
       this._setButtons($tip, buttons);
    }
    
    getBtnHtml(button){
        var self = this;
        var btn = jQuery('<a></a>').addClass(BTN_CLASS_BASE).addClass(button["class"] || BTN_CLASS_DEFAULT + " btn-secondary").html(button.label || '').attr(button.attr || (button.cancel ? {} : self._config._attributes));
        
        
        if (button.iconClass || button.iconContent) {
          btn.prepend($('<i></i>').addClass(button.iconClass || '').text(button.iconContent || ''));
        }

        if (button.cancel) {
             btn.addClass("cancel-confirmation-btn");
        }
        else{
             btn.addClass("confirm-confirmation-btn");            
        }
        
        console.log("BEFORE EVENT ASSIGN"); 
        //LES EVENEMENTS NE SONT PAS ASSIGNES CAR ON RETOURNE DE l'HTML ET QUE LES ELEMENTS NE SONT PAS CREERS
        btn.one("click",function (e) {
          console.log("CLICK EVENT");
          if ($(this).attr('href') === '#') {
            console.log("IS ATTR #");
            e.preventDefault();
          }
          
          if (button.onClick) {
              console.log("ON CLICK");
            button.onClick.call($(self._element));
          }

          if (button.cancel) {
              console.log("ON CANCEL BUTTON");
            self._config.onCancel(self._element, button.value);
            $(self._element).trigger(Event.CANCELED, [button.value]);
          } else {
              console.log("ON CONFIRM BUTTON");
            self._config.onConfirm(self._element, button.value);
            $(self._element).trigger(Event.CONFIRMED, [button.value]);
            $(self._element).trigger(self._config.confirmationEvent, [true]);
          }

          self.hide();
        });
        return btn;
    }
    
    _setButtons($tip, buttons) {
      var self = this;
      console.log($tip);
      console.log($tip.html());
      var $group = $tip.find(Selector.BUTTONS).empty();
      
      buttons.forEach(function (button) {
          var btn = self.getBtnHtml(button);
        $group.append(btn);
        
    //    self._element.setAttribute("data-bs-content", $group.html());
    //    self._config.content = $group.html();
      });
    }
    
    //CA MARCHE!! VOir pour rajouter les boutons dans le template puisque là on modifie directement les boutons et on l'assigne au content

  _getContent() {
    return this._resolvePossibleFunction(this._config.content);
  }

  getButtonsView() {
    var btnHtml = "";
    var self = this;
    this._config.buttons.forEach(function (button) {
        var btn = self.getBtnHtml(button);
        console.log(btn);
        btnHtml += btn[0].outerHTML;
    });
    return this._resolvePossibleFunction(btnHtml);      
  }

  EventClickOutsiteAdded = false;

 show() {
    if (this._element.style.display === 'none') {
      throw new Error('Please use show on visible elements')
    }

    if (!(this._isWithContent() && this._isEnabled)) {
      return
    }

    const showEvent = EventHandler.trigger(this._element, this.constructor.eventName(Event.SHOW))
    const shadowRoot = findShadowRoot(this._element)
    const isInTheDom = (shadowRoot || this._element.ownerDocument.documentElement).contains(this._element)

    if (showEvent.defaultPrevented || !isInTheDom) {
      return
    }

    // TODO: v6 remove this or make it optional
    this._disposePopper()

    const tip = this._getTipElement()
    const $tip = jQuery(tip);
    
    if (this._config.buttons.length > 0) {
          console.log("INIT BUTTONS BECAUSE ALREADY BUTTONS");
        this._setButtons($tip, this._config.buttons);
    } else {
          console.log("INIT WITH STANDARD BUTTON");
        this._setStandardButtons($tip);
    }

    this._element.setAttribute('aria-describedby', tip.getAttribute('id'))

    const { container } = this._config

    if (!this._element.ownerDocument.documentElement.contains(this.tip)) {
      container.append(tip)
      EventHandler.trigger(this._element, this.constructor.eventName(Event.INSERTED))
    }

    this._popper = this._createPopper(tip)

    tip.classList.add(ClassName.SHOW)

    // If this is a touch-enabled device we add extra
    // empty mouseover listeners to the body's immediate children;
    // only needed because of broken event delegation on iOS
    // https://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html
    if ('ontouchstart' in document.documentElement) {
      for (const element of [].concat(...document.body.children)) {
        EventHandler.on(element, 'mouseover', noop)
      }
    }
    var self = this;
    if(!this.EventClickOutsiteAdded){    
        jQuery("body").on("click",function(e){
            var typ2 = jQuery(self._getTipElement());
            console.log(typ2);
            if (typ2.has(e.target).length === 0){
                    console.log("OUTSIDE TIP!!");
                    console.log(typ2.html());
                    self._config.onCancel(self._element);
                    jQuery(self._element).trigger(Event.CANCELED, []);                
                    self.hide();        
    //ICI CA SUPPRIUME LES EVENEMENTS DE CLICK DONC SI ON FERME UNE FENETRE ALORS QU UNE AUTRE EST OUVERTE IL FAUT PAS FAIRE CA
                }                                           
                else{
                    console.log("INSIDE TIP!!");                    
                }
        }); 
        //L'evenement n'est pas supprimé
        this.EventClickOutsiteAdded = true;
    }
    const complete = () => {
      EventHandler.trigger(this._element, this.constructor.eventName(Event.SHOWN))

      if (this._isHovered === false) {
        this._leave()
      }

      this._isHovered = false
    }

    this._queueCallback(complete, this.tip, this._isAnimated())
  }





  initButtons(){
      var $tip = $(this._getTipElement());
      if (this._config.buttons.length > 0) {
          console.log("INIT BUTTONS BECAUSE ALREADY BUTTONS");
        this._setButtons($tip, this._config.buttons);
      } else {
          console.log("INIT WITH STANDARD BUTTON");
        this._setStandardButtons($tip);
      }
  }
  // Static

    static _jQueryInterface(config) {
      return this.each(function () {
        var data = jQuery(this).data(DATA_KEY);

        var _config = typeof config === 'object' ? config : {};

        _config.rootSelector = jQuery(this).selector || _config.rootSelector; // this.selector removed in jQuery > 3

        if (!data && /destroy|hide/.test(config)) {
          return;
        }

        if (!data) {
          data = new Confirmation(this, _config);
          jQuery(this).data(DATA_KEY, data);
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError("No method named \"" + config + "\"");
          }

          data[config]();
        }
      });
    };

    _copyAttributes() {
      var _this2 = this;

      this._config._attributes = {};

      if (this._config.copyAttributes) {
        if (typeof this._config.copyAttributes === 'string') {
          this._config.copyAttributes = this._config.copyAttributes.split(' ');
        }
      } else {
        this._config.copyAttributes = [];
      }

      this._config.copyAttributes.forEach(function (attr) {
        _this2._config._attributes[attr] = $(_this2._element).attr(attr);
      });
    }

    dispose() {
      jQuery('body').off(Event.CLICK + "." + this.uid);
      this.eventBody = false;

      this._cleanKeyupEvent();

      super.dispose();
    }

    hide(callback) {
      this._cleanKeyupEvent();

      super.hide(callback);
    } // Private


     activeConfirmation;

 /**
     * Install the keyboatd event handler
     * @private
     */
    _setupKeyupEvent() {
      this.activeConfirmation = this;
      jQuery(window).off(Event.KEYUP).on(Event.KEYUP, this._onKeyup.bind(this));
    }
    /**
     * Remove the keyboard event handler
     * @private
     */
    

    _cleanKeyupEvent() {
      if (this.activeConfirmation === this) {
        this.activeConfirmation = undefined;
        jQuery(window).off(Event.KEYUP);
      }
    }
    /**
     * Event handler for keyboard navigation
     * @param event
     * @private
     */
    

    _onKeyup(event) {
      if (!this.tip) {
        this._cleanKeyupEvent();

        return;
      }

      var $tip = $(this._getTipElement());
      var key = event.key || Keymap[event.keyCode || event.which];
      var $group = $tip.find(Selector.BUTTONS);
      var $active = $group.find('.active');
      var $next;

      switch (key) {
        case 'Escape':
          this.hide();
          break;

        case 'ArrowRight':
          if ($active.length && $active.next().length) {
            $next = $active.next();
          } else {
            $next = $group.children().first();
          }

          $active.removeClass('active');
          $next.addClass('active').focus();
          break;

        case 'ArrowLeft':
          if ($active.length && $active.prev().length) {
            $next = $active.prev();
          } else {
            $next = $group.children().last();
          }

          $active.removeClass('active');
          $next.addClass('active').focus();
          break;
      }
      
      
      
    }



     /**
     * Generates an uui, copied from Bootrap's utils
     * @param {string} prefix
     * @returns {string}
     */
    static getUID = function getUID(prefix) {
      var uid = prefix;

      do {
        // eslint-disable-next-line no-bitwise
        uid += ~~(Math.random() * 1000000); // "~~" acts like a faster Math.floor() here
      } while (document.getElementById(uid));

      return uid;
    };
}




/**
 * jQuery
 */

  jQuery.fn[NAME] = Confirmation._jQueryInterface;
  jQuery.fn[NAME].Constructor = Confirmation;

  jQuery.fn[NAME].noConflict = function () {
    jQuery.fn[NAME] = JQUERY_NO_CONFLICT;
    return Confirmation._jQueryInterface;
  };
export default Confirmation
